import * as React from 'react';
import LinkButton from './linkButton/linkButton';
import PhotoBlock, { PhotoBlockModel } from './photo-block/photo-block';
import Axios from 'axios';
import InfoData from '../info-data';
import teirautis from '../assets/teirautis-del-susitikimo.webp';

const blockConfig: PhotoBlockModel = {
	imgUrl: teirautis,
	imgAlt: 'Simona Grigaraitė',
	title: 'Parašykite žinutę',
	backgroundColor: 'white',
	loading: 'eager'
};

const MessageMe = (props: any) => {
	const [form, setForm] = React.useState({ name: '', email: '', message: '', type_of_consultation: null });

	const [isSubmitted, setIsSubmitted] = React.useState(false);

	const handleSubmit = async (event: any) => {
		event?.preventDefault();
		console.log(form);

		const response = await Axios.post(`https://api.emailjs.com/api/v1.0/email/send`, {
			service_id: InfoData.emailServiceId,
			template_id: InfoData.emailTemplateId,
			user_id: InfoData.emailUserId,
			template_params: form
		}).catch((error) => {
			console.log('error', error);
		});
		if ((response as any).status === 200) {
			setIsSubmitted(true);
		}
	};

	const onValueChange = (event: any) => {
		const f: any = { ...form };
		f[event.target.name] = event.target?.value;
		setForm(f);
	};

	return (
		<PhotoBlock {...blockConfig} title={isSubmitted ? null : blockConfig.title} superScript={isSubmitted ? null : blockConfig.superScript}>
			{!isSubmitted ? (
				<form onSubmit={handleSubmit}>
					<label className="is-required">
						<span>Vardas</span>
						<input type="text" value={form.name} required placeholder="Jūsų vardas" name="name" onChange={onValueChange}></input>
					</label>
					<label className="is-required">
						<span>Jūsų el. paštas</span>
						<input
							type="email"
							value={form.email}
							required
							placeholder="Jūsų el. pašto adresas"
							name="email"
							onChange={onValueChange}></input>
					</label>
					<label className="is-required">
						<span>Žinutė</span>
						<textarea
							value={form.message}
							required
							placeholder="Trumpai aprašykite savo poreikius"
							name="message"
							onChange={onValueChange}></textarea>
					</label>
					<div className="is-required">
						<span>Konsultacijos tipas</span>
						<label className="radio-opt" style={{ width: 'fit-content' }}>
							<input
								type="radio"
								name="type_of_consultation"
								value="internetu"
								checked={form.type_of_consultation === 'internetu'}
								onChange={onValueChange}
								required
							/>
							<span>Internetu</span>
						</label>
						<label className="radio-opt" style={{ width: 'fit-content' }}>
							<input
								type="radio"
								name="type_of_consultation"
								value="kabinete"
								checked={form.type_of_consultation === 'kabinete'}
								onChange={onValueChange}
								required
							/>
							<span>Kabinete</span>
						</label>
					</div>

					<LinkButton isButton={true}>Siųsti</LinkButton>
				</form>
			) : (
				<div className="submit-success">
					<span className="submit-success__icon"></span>
					<h2 className="submit-success__title">Jūsų žinutė išsiųsta</h2>
					<p className="submit-success__sub">Su jumis bus susisiekta el. paštu.</p>
				</div>
			)}
		</PhotoBlock>
	);
};

export default MessageMe;
